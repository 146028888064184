import { FC } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import InputValidation from 'components/form-parts/input-validation-react/input-validation-react'

import { fieldData } from 'constants/auth/profile-data'

interface IProps {
  control: Control<any, any>
  errors: FieldErrors<any>
  noErrorOnTouch?: any
  dynamicName?: boolean
  rules?: {
    required: string
    validate?: (val: string) => string | undefined
  }
  name: string
  id?: string // Этот айди нужен для скрипта скринов
  type?: string
  step?: string
}

const isNotEmpty = (str: string) => str?.trim() !== ''

const ControllerBaseInput: FC<IProps> = ({
  control,
  errors,
  rules,
  name,
  dynamicName,
  noErrorOnTouch,
  ...props
}: IProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { ref, value, ...rest } }) => {
        return (
          <InputValidation
            setInputRef={ref}
            dynamicName={dynamicName}
            error={errors[name]}
            onClick={() => noErrorOnTouch?.(name)}
            value={isNotEmpty(value) ? value : ''}
            {...fieldData[name]}
            {...rest}
            {...props}
          />
        )
      }}
    />
  )
}

export default ControllerBaseInput
